import React from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/_contact.scss"
import "../styles/secondary_pages.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'


import img1 from "../images/contact-us-pic.jpg"

const phoneRegex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

const emailRegex =
  RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

export default class InboundLeadForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formValid: false,
      txtFullName: "",
      txtFullNamePassed: false,
      txtFullNameValid: false,
      txtEmail: "",
      txtEmailPassed: false,
      txtEmailValid: false,
      txtPhone: "",
      txtPhonePassed: false,
      txtPhoneValid: false,
      txtMessage: "",
      txtPracticeName: "",
      txtPracticeNamePassed: false,
      txtPracticeNameValid: false,
      txtSpecialtyName: "",
      txtSpecialtyNamePassed: false,
      txtSpecialtyNameValid: false,
      txtPracticeLocation: "",
      txtPracticeLocationPassed: false,
      txtPracticeLocationValid: false,
      slcBillingType: "",
      txtNumberPhysicians: "",
      txtNumberPhysiciansPassed: false,
      txtNumberPhysiciansValid: false



    }


    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handlePracticeNameChange = this.handlePracticeNameChange.bind(this);
    this.handleSpecialtyNameChange = this.handleSpecialtyNameChange.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
    this.handleNumberPhysicians = this.handleNumberPhysicians.bind(this);




    this.handleSlcBillingTypeChange = this.handleSlcBillingTypeChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.isFormValid = this.isFormValid.bind(this);

    this.state.formValid = this.isFormValid();
  }

  handleSlcBillingTypeChange(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      slcBillingType: value
    });
  }

  handleLocation(event) {
    const target = event.target;
    const value = target.value;
    const valid = value.length > 0;

    this.setState({
      txtPracticeLocation: value,
      txtPracticeLocationValid: valid
    });

    this.setState({ 'formValid': this.isFormValid() });
  }
  handleNumberPhysicians(event) {
    const target = event.target;
    const value = target.value;
    const valid = value.length > 0;

    this.setState({
      txtNumberPhysicians: value,
      txtNumberPhysiciansValid: valid
    });

    this.setState({ 'formValid': this.isFormValid() });
  }
  handleSpecialtyNameChange(event) {
    const target = event.target;
    const value = target.value;
    const valid = value.length > 0;

    this.setState({
      txtSpecialtyName: value,
      txtSpecialtyNameValid: valid
    });

    this.setState({ 'formValid': this.isFormValid() });
  }
  handlePracticeNameChange(event) {
    const target = event.target;
    const value = target.value;
    const valid = value.length > 0;

    this.setState({
      txtPracticeName: value,
      txtPracticeNameValid: valid
    });

    this.setState({ 'formValid': this.isFormValid() });
  }

  handleFullNameChange(event) {
    const target = event.target;
    const value = target.value;
    const valid = value.length > 0;

    this.setState({
      txtFullName: value,
      txtFullNameValid: valid
    });

    this.setState({ 'formValid': this.isFormValid() });
  }

  handleEmailChange(event) {
    const currentValue = event.target.value;
    const valid = emailRegex.test(currentValue);

    if (valid) {
      this.setState({ 'txtEmail': currentValue, 'txtEmailValid': true });
    }
    else {
      this.setState({ 'txtEmailValid': false });
    }
  }

  isFormValid() {
    const formIsValid = this.state.txtFullNameValid && this.state.txtPhoneValid && this.state.txtEmailValid;

    return formIsValid;
  }
  handlePhone(event) {
    const currentValue = event.target.value;

    this.setState({
      'txtPhone': currentValue
    });
    if (currentValue.length === 0 || phoneRegex.test(currentValue)) {
      this.setState({ 'txtPhoneValid': true });
    }
    else {
      this.setState({ 'txtPhoneValid': false });
    }
    this.setState({ 'formValid': this.isFormValid() });
  }

  handleBlur(event) {
    const target = event.target;
    const { name, value } = target;

    this.setState({
      [name + 'Passed']: true
    });

    this.setState({ 'formValid': this.isFormValid() });
  }

  render() {
    const SlcOptions = [
      { label: "In House", value: "inhouse" },
      { label: "Outsourced", value: "outsourced" },
    ]



    return (
      <Layout styleClass="contact secondary-form" >
        <SEO title="Our New Year's Gift to You!" />
        <section id="sct-pg-hdr" className="container ctr-headline secondary-headline">
          <h2>
            <span>Our New Year's Gift to You</span></h2>
          <p>MSM is pleased to offer a small, select group of Radiologists an exclusive Practice Health Check.  The Practice Health Check is a Revenue Cycle audit that ensures you are maximizing collections and capturing all of the money that is due to you.  Practices will be able to utilize the MSM Health Check as a means of identifying areas for improvement, validating current process and procedures, and ultimately optimizing revenue.</p>
        </section>

        <section className="ctr-form column-main">
          <div className="ctr-section-header">
            <h3>
              Fill out this form to participate in Practice Health Check:
            </h3>
            <p>
              Please enter all * required fields.
          </p>
          </div>
          <form name="new_years_practice_health_check_signup" action="/form_response/" netlify-honeypot="bot-field" method="POST" data-netlify="true" onBlur={this.handleBlur}>
            <input type="hidden" name="form-name" value="new_years_practice_health_check_signup" />

            <input type="hidden" name="bot-field" />
            <label htmlFor="txtFullName">Your Name *</label>
            <input id="txtFullName" name="txtFullName" type="text" placeholder=""
              className={`${(this.state.txtFullNamePassed && !this.state.txtFullNameValid) ? 'error' : ''}`}
              onChange={this.handleFullNameChange} value={this.state.txtFullName} onBlur={this.handleBlur} />

            <span className={`${(this.state.txtFullNamePassed && !this.state.txtFullNameValid) ? 'error' : 'error error-hidden'}`}>Your name is required.</span>

            <label for="txtPracticeName">Practice Name *</label>
            <input id="txtPracticeName" name="txtPracticeName" type="text" placeholder=""
              className={`${(this.state.txtPracticeNamePassed && !this.state.txtPracticeNameValid) ? 'error' : ''}`}
              onChange={this.handlePracticeNameChange} value={this.state.txtPracticeName} onBlur={this.handleBlur} />
            <span className={`${(this.state.txtPracticeNamePassed && !this.state.txtPracticeNameValid) ? 'error' : 'error error-hidden'}`}>Your practice name is required.</span>

            <label for="txtSpecialtyName">Specialty *</label>
            <input id="txtSpecialtyName" name="txtSpecialtyName" type="text" placeholder=""
              className={`${(this.state.txtSpecialtyNamePassed && !this.state.txtSpecialtyNameValid) ? 'error' : ''}`}
              onChange={this.handleSpecialtyNameChange} value={this.state.txtSpecialtyName} onBlur={this.handleBlur}

            />
            <span className={`${(this.state.txtSpecialtyNamePassed && !this.state.txtSpecialtyNameValid) ? 'error' : 'error error-hidden'}`}>Your Specialty is required.</span>

            <label htmlFor="txtEmail">Phone Number *</label>
            <input id="txtPhone" name="txtPhone" type="tel"
              onChange={this.handlePhone} value={this.state.txtPhone}
              className={`${(this.state.txtPhonePassed && !this.state.txtPhoneValid) ? 'error' : ''}`}
            />
            <span className={`${(this.state.txtPhonePassed && !this.state.txtPhoneValid) ? 'error' : 'error error-hidden'}`}>Please provide a valid phone number.</span>

            <label htmlFor="txtEmail">Email *</label>
            <input id="txtEmail" className={`${(this.state.txtEmailPassed && !this.state.txtEmailValid) ? 'error' : ''}`} name="txtEmail" type="text" placeholder=""
              onChange={this.handleEmailChange} onBlur={this.handleBlur} value={this.state.txtEmail.value} />
            <span className={`${(this.state.txtEmailPassed && !this.state.txtEmailValid) ? 'error' : 'error error-hidden'}`}>Email is required.</span>

            <label for="txtPracticeLocation">Practice Location *</label>
            <input id="txtPracticeLocation"
              className={`${(this.state.txtPracticeLocationPassed && !this.state.txtPracticeLocationValid) ? 'error' : ''}`}
              name="txtPracticeLocation" type="text" placeholder=""
              onBlur={this.handleBlur}
              onChange={this.handleLocation} value={this.state.txtPracticeLocation}
            />
            <span className={`${(this.state.txtPracticeLocationPassed && !this.state.txtPracticeLocationValid) ? 'error' : 'error error-hidden'}`}>Your practice location is required.</span>

            <label for="txtNumberPhysicians">Number of Physicians in Practice:</label>
            <input id="txtNumberPhysicians" name="txtNumberPhysicians" type="text" placeholder=""
              onChange={this.handleNumberPhysicians} value={this.state.txtNumberPhysicians}
            />
            <span className="error-hidden">&nbsp;</span>

            <label for="slcBillingType">Is your billing in-house or outsourced?</label>

            <select id="slcBillingType"
              name="slcBillingType"
              searchable={false}

              onChange={this.handleSlcBillingTypeChange}
              className="msm-theme"
              value={this.state.slcBillingType}
            >
              <option value="inhouse">In House</option>
              <option value="outsourced">Outsourced</option>
            </select>

            <span className="error-hidden">&nbsp;</span>
            <label htmlFor="txtMessage">Do you have any revenue cycle or operational challenges you would like reviewed? Please tell us.</label>
            <textarea name="txtMessage" id="" cols="30" rows="10" defaultValue={this.state.txtMessage}></textarea>

            <button className="btn-primary" disabled={!this.state.formValid}>Sign up for Practice Health Check</button>
          </form>
          <div className="ctr-section-footer" style={{ 'display': 'none' }}>
            Thank you for signing up for MSM Practice Health Check. Our team will be contacting you very shortly using the information you provided. Looking forward to helping your practice thrives.
            </div>
        </section>







      </Layout >
    )
  }
}


